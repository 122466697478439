import admin from './admin.json';
import common from './common.json';
import form from './form.json';
import language from './language.json';
import login from './login.json';
import profile from './profile.json';
import register from './register.json';
import statistic from './statistic.json';
import submission from './submission.json';
import type from './type.json';
import user from './user.json';
import validation from './validation.json';

export const de = {
  admin,
  common,
  form,
  language,
  login,
  profile,
  register,
  statistic,
  submission,
  type,
  user,
  validation,
};
