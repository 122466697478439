import { ar } from './ar';
import { cn } from './cn';
import { da } from './da';
import { de } from './de';
import { en } from './en';
import { es } from './es';
import { fr } from './fr';
import { hi } from './hi';
import { it } from './it';
import { ja } from './ja';
import { nl } from './nl';
import { pl } from './pl';
import { pt_BR } from './pt_BR';
import { pt_PT } from './pt_PT';
import { ru } from './ru';
import { sv } from './sv';
import { ta } from './ta';
import { uk } from './uk';

export const resources = {
  en,
  ar,
  cn,
  da,
  nl,
  fr,
  de,
  hi,
  it,
  ja,
  pl,
  pt_BR,
  pt_PT,
  ru,
  es,
  sv,
  ta,
  uk,
};
